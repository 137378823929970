import { ComponentType } from "react";
import ErrorBoundary from "./ErrorBoundary";

export const withErrorBoundary = <Props extends {}>(Component: ComponentType<Props>, boundaryName: string) => {
  const ComponentWithErrorBoundary = (props: Props) => (
    <ErrorBoundary boundaryName={boundaryName}>
      <Component {...props} />
    </ErrorBoundary>
  );

  ComponentWithErrorBoundary.displayName = Component.displayName;

  return ComponentWithErrorBoundary;
};
